import { useEffect, useState } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const format = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});
export interface IInvoiceTemplate {
  number: null | number;
  address1: string;
  address2: string;
  state_name: string;
  city: string;
  first_name: string;
  id: null | number;
  labor: string;
  last_name: string;
  order_id: number;
  parts: string;
  phone: string;
  service_call: string;
  street: null | string;
  tax: string;
  technician_id: number;
  technician_signature: null | string;
  total_charges: string;
  updated_at: null | string;
  zip_code: string;
  pdf: null | string;
  file: null | string;
  email: string | null;
  discarded_at: null | number;
}
export const Square = () => {
  const [isReady, setIsReady] = useState(false);
  const [appId, setAppId] = useState("");
  const [accessToken, setAccessToken] = useState();
  const [locationId, setLocationId] = useState("");
  const [amount, setAmount] = useState<number | null>(null);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errorText, setErrorText] = useState("");
  const [transId, setTransId] = useState<string | null>(null);
  const [usrToken, setUsrToken] = useState<string | null>(null);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setErrorText("");
    if (location.search.includes("?txId=")) {
      const splitted = location.search.split("&");
      const txId = splitted?.[0]?.split("=")?.[1];
      const userToken = splitted?.[1]?.split("=")?.[1];
      const companyId = splitted?.[2]?.split("=")?.[1];
      const orderId = splitted?.[3]?.split("=")?.[1];
      const invoiceId = splitted?.[4]?.split("=")?.[1];

      console.log("txId", txId);
      console.log("userToken", userToken);
      console.log("companyId", companyId);
      console.log("orderId", orderId);
      console.log("invoiceId", invoiceId);

      setTransId(txId);
      setUsrToken(userToken);

      if (txId && userToken && companyId && orderId) {
        try {
          axios
            .get(`https://spark.localigniter.com/api/v1/payments/${txId}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: userToken,
              },
            })
            .then((res) => {
              const transactionData = res.data;
              console.log("transactionData", transactionData);
              if (transactionData.payment_status === "unpaid") {
                if (transactionData?.id && transactionData?.amount && orderId) {
                  setAmount(parseFloat(transactionData.amount));
                  axios
                    .get(
                      `https://spark.localigniter.com/api/v1/orders/${orderId}/invoices`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: userToken,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("invoices", res.data);
                      const invoices = res.data as IInvoiceTemplate[];
                      const invoice = invoices.find(
                        (i: IInvoiceTemplate) =>
                          Number(i.id) === Number(invoiceId)
                      );
                      if (invoice) {
                        setAddress1(invoice.address1 || "");
                        setAddress2(invoice.address2 || "");
                        setCity(invoice.city || "");
                        setFirstName(invoice.first_name || "");
                        setLastName(invoice.last_name || "");
                        setState(invoice.state_name || "");
                        setZipCode(invoice.zip_code || "");
                        axios
                          .get(
                            `https://spark.localigniter.com/api/v1/companies/${companyId}`,
                            {
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: userToken,
                              },
                            }
                          )
                          .then((companyRes) => {
                            console.log("companyRes", companyRes);
                            console.log(
                              "companyRes?.data?.payment_methods?.square",
                              companyRes?.data?.payment_methods?.square
                            );
                            if (
                              companyRes?.data?.payment_methods?.square?.enabled
                            ) {
                              if (
                                companyRes?.data?.payment_methods?.square
                                  ?.access_token
                              ) {
                                setAppId(
                                  companyRes?.data?.payment_methods?.square
                                    ?.app_id
                                );
                                setAccessToken(
                                  companyRes?.data?.payment_methods?.square
                                    ?.access_token
                                );
                                setLocationId(
                                  companyRes?.data?.payment_methods?.square
                                    ?.location_id
                                );
                                setIsReady(true);
                              } else {
                                setErrorText("Error - No access token");
                              }
                            } else {
                              setErrorText("Error - Square not enabled");
                            }
                          });
                      } else {
                        setErrorText("Error - No invoice");
                      }
                    })
                    .catch((e) => {
                      console.log("e", e);
                    });
                } else {
                  if (!orderId) {
                    setErrorText("Error - No order id");
                  } else if (!transactionData?.amount) {
                    setErrorText("Error - No amount");
                  } else {
                    setErrorText("Error - No transaction id");
                  }
                }
              } else {
                setErrorText("Error - Transaction alredy paid");
              }
            });
        } catch (e) {
          setErrorText("Error - Transaction not found");
        }
      } else {
        if (!txId) {
          setErrorText("Error - No transaction ID");
        } else if (!userToken) {
          setErrorText("Error - No user token");
        } else if (!orderId) {
          setErrorText("Error - No order Id");
        } else {
          setErrorText("Unknown error occured");
        }
      }
    } else {
      setErrorText("Unknown error occured");
    }
  }, [location.search]);

  if (isPaymentSuccess) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <h2 style={{ color: "green" }}>Successfully paid</h2>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <div style={{ width: "100%", maxWidth: 1000, padding: 20 }}>
        {isReady &&
        locationId &&
        appId &&
        amount &&
        city &&
        transId &&
        accessToken ? (
          <>
            <h2>{format.format(amount)} will be charged</h2>
            <PaymentForm
              /**
               * Identifies the calling form with a verified application ID generated from
               * the Square Application Dashboard.
               */
              //applicationId="sandbox-sq0idb-ctGDjYtw2R-O6QbZtpm9lA"
              applicationId={appId}
              /**
               * Invoked when payment form receives the result of a tokenize generation
               * request. The result will be a valid credit card or wallet token, or an error.
               */
              cardTokenizeResponseReceived={(token, buyer) => {
                console.log("token", token);
                console.log("buyer", buyer);
                if (token && token.status === "OK") {
                  setIsPaymentInProgress(true);
                  axios
                    .post(
                      `https://server.rxchildren.com/api/v1/app/square-create-transaction`,
                      {
                        idempotency_key: `${transId}${Date.now()}`,
                        source_id: token.token,
                        amount_money: {
                          amount: Math.round(amount * 100),
                          currency: "USD",
                        },
                        billing_address: {
                          address_line_1: address1,
                          address_line_2: address2,
                          country: "US",
                          first_name: firstName,
                          last_name: lastName,
                          postal_code: zipCode,
                          administrative_district_level_1: state,
                          locality: city,
                        },
                        //verification_token: token.token,
                        access_token: accessToken,
                        usr_token: usrToken,
                        trans_id: transId,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      console.log("res", res);
                      if (res.data.resp === "error") {
                        setIsPaymentError(true);
                      } else {
                        setIsPaymentError(false);
                        setIsPaymentSuccess(true);
                      }
                      setIsPaymentInProgress(false);
                    });
                } else {
                  setIsPaymentError(true);
                }

                console.info({ token, buyer });
              }}
              /**
               * This function enable the Strong Customer Authentication (SCA) flow
               *
               * We strongly recommend use this function to verify the buyer and reduce
               * the chance of fraudulent transactions.
               */
              createVerificationDetails={() => ({
                amount: "1.00",
                /* collected from the buyer */
                billingContact: {
                  addressLines: [address1, address2],
                  familyName: lastName,
                  givenName: firstName,
                  state: state,
                  countryCode: "US",
                  city: city,
                },
                currencyCode: "USD",
                intent: "CHARGE",
              })}
              /**
               * Identifies the location of the merchant that is taking the payment.
               * Obtained from the Square Application Dashboard - Locations tab.
               */
              //locationId="LQMCEZ72RBETM"
              locationId={locationId}
            >
              <CreditCard
                buttonProps={{
                  isLoading: isPaymentInProgress ? true : undefined,
                }}
                //postalCode={zipCode}
              />
            </PaymentForm>
            {isPaymentError && (
              <h2 style={{ color: "red" }}>
                Payment error - Check card details.
              </h2>
            )}
            {isPaymentInProgress && (
              <h2 style={{ color: "grey" }}>
                Please wait, payment in progress...
              </h2>
            )}
          </>
        ) : (
          <h2>{errorText || "Loading..."}</h2>
        )}
      </div>
    </div>
  );
};
